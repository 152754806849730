<script lang="ts" setup>
withDefaults(defineProps<{ displayText?: boolean }>(), { displayText: true })

const { localeSelectOptions, currentLocaleCode, currentLocaleProperties } = useI18nLocale()

function getLocaleIcon(locale?: string | null) {
  if (locale === 'en') {
    return 'cif:gb'
  }
  return 'cif:de'
}
</script>

<template>
  <n-popselect v-model:value="currentLocaleCode" :options="localeSelectOptions" placement="top-start">
    <n-button type="primary" secondary color="white">
      <template #icon>
        <Icon :name="getLocaleIcon(currentLocaleCode)" class="rounded" />
      </template>
      <template v-if="displayText">
        {{ currentLocaleProperties.name ?? currentLocaleCode }}
      </template>
    </n-button>
  </n-popselect>
</template>
